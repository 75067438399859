<template>
  <tr>
    <td class="flex flex-row h-full gap-2">
      <img
        alt=""
        class="h-8 my-auto"
        :src="assetsBaseUrl + '/content/images/flags/' + country.id + '.png'"
      />
      <span class="my-auto">{{ country.name }}</span>
      <span class="my-auto text-gray-500">({{ country.id }})</span>
    </td>
    <td class="pl-2">
      <input
        type="number"
        :id="transactionId + '__' + country.id"
        :class="[inputClasses, { 'text-gray-300': isLoading }]"
        :disabled="isLoading"
        :max="max"
        :min="0"
        v-model="quantity"
        @input="onInput"
      >
      <!-- <FontAwesomeIcon
        v-if="isLoading"
        class="absolute top-[50%] left-[50%]"
        :icon="['far', 'spinner']"
        pulse
      /> -->
    </td>
  </tr>
</template>

<script>
import http from "@/http"

export default {
  props: {
    country: { type: Object, required: true },
    hasError: { type: Boolean, default: false },
    max: { type: Number, default: 0 },
    transactionCustom: { type: Object, required: false },
    transactionId: { type: String, required: true },
  },
  emits: ['updateCustom', 'removeCustom'],
  data() {
    return {
      isLoading: false,
      debounce: null,
      quantity: 0,
    }
  },
  mounted() {
    if (this.transactionCustom) this.quantity = this.transactionCustom.quantity
  },
  computed: {
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    },
    inputClasses() {
      const classes = ["p-2 h-8 shadow-inner border rounded leading-none max-w-full w-24 focus:outline-none text-right"]
      if (this.hasError) classes.push("border-red-500 focus:border-red-600 placeholder-red-300 text-red-600")

      return classes
    },
  },
  methods: {
    onInput() {
      // this.quantity = Number(event.target?.value || '0')

      if (this.debounce) clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => await this.saveTransactionCustom(), 700)
    },
    async saveTransactionCustom() {
      if (this.isLoading === true) return
      try {
        this.isLoading = true

        const res = await http.post('TransactionCustom', { transactionId: this.transactionId, countryId: this.country.id, quantity: this.quantity })
        // console.log("response:", res)
        if (res.length) {
          this.quantity = res[0].quantity
          this.$emit('updateCustom', res[0])
        } else {
          this.quantity = 0
          this.$emit('removeCustom', this.country.id)
        }
        this.isLoading = false

      } catch (e) {
        console.error("could not update customs:", e)
        this.quantity = 0
        this.isLoading = false
        this.$emit('removeCustom', this.country.id)
      }
    }
  },
}
</script>
