<template>
  <PDialogBody>
    <PDialogContent :loading="isLoading">
      
      <div ref="comments">

        <div 
          v-for="(comment, index) in comments" 
          :ref="`comment-${index}`"
          :key="index"
        >

          <div class="mt-4" :class="{ 'flex justify-end': user.id === comment.userId }">
            <div class="w-3/5 p-4 bg-gray-200 rounded-lg" :class="{ 'bg-green-200 bg-gradient-to-b': user.id === comment.userId }">
              
              <!-- header -->
              <div class="flex items-baseline justify-between text-sm">
                <div class="font-medium">
                  {{ comment.userName }}
                </div>
                <div class="text-xs">{{ comment.createdLocal | dateAndTime }}</div>
              </div>
              
              <!-- body -->
              <div class="mt-4 text-sm">{{ comment.content }}</div>
              
              <!-- attachments -->
              <template v-if="comment.attachmentData">
                
                <!-- image attachment -->
                <template v-if="isImage(comment.attachmentType)">
                  <PThumbnailImage
                    class="mt-4"
                    :data="comment.attachmentData"
                    :name="comment.attachmentName"
                  />                  
                </template>

                <!-- pdf attachment -->
                <template v-else-if="isPdf(comment.attachmentType)">
                  
                  <div class="flex items-center mt-4">
                    
                    <FontAwesomeIcon :icon="['far', 'file-pdf']" />
                    
                    <!-- TODO: - bruk heller attachment data. use withAttachment to get lenght + type. use AttachmentData api for data. -->
                    <a 
                      class="ml-1 underline"
                      :download="comment.attachmentName" 
                      :href="`data:application/pdf;base64,${comment.attachmentData}`" 
                    >
                    <span class="text-sm">
                        {{ comment.attachmentName }}
                    </span>
                    </a>

                  </div>
                </template>

              </template>
              
            </div>
          </div>
        </div>
      </div>
    </PDialogContent>
    
    <div v-if="addCommentVisible" class="p-4 border-t border-gray-300 flex-0">
      
      <PTextarea 
        label="Din kommentar" 
        :disabled="isPosting"
        :required="true"
        v-model="content"
      ></PTextarea>      

      <div class="flex items-center justify-between gap-4">

        
        <div>
          <PFile 
          class="mt-4"        
          :disabled="isPosting"
          @change="onFileChange"
          /> 
          
          <div 
            class="mt-1 text-xs" 
            v-html="$tk('EditDialogComments.AllowedFiletypes')"
          ></div>

        </div>

        <PButton
          class="ml-2" 
          v-if="addCommentVisible"
          color="primary" 
          icon="paper-plane" 
          size="sm" 
          @click="onSend"
          :disabled="!canComment"
          :loading="isPosting">
          <span v-html="$tk('EditDialogComments.SendComment')"></span>
        </PButton>

      </div>

    </div>

    <PDialogActions>
      
      <PButton 
        @click="$emit('close')" 
        color="secondary" 
        class="ml-2"        
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>

    </PDialogActions>    

  </PDialogBody>
</template>

<script>

import http from "@/http"
import { some } from "lodash"
import { mapGetters } from "vuex"

export default {
  props: ["orderId"],
  data () {
    return {
      order: null,
      file: "",
      content: "",
      comments: [],
      selected: null,
      isLoading: false,
      isPosting: false,
      getCommentsTimer: null,  
    }
  },
  computed: {
    
    ...mapGetters(["user"]),

    canComment () {
      return this.content !== ""
    },

    addCommentVisible () {
      return this.order !== null && this.order.transactionStatusId !== 5
            //  (this.order.transactionStatusId === 4 ||
            //   this.order.transactionStatusId === 6 ||
            //   this.order.transactionStatusId === 15)
    }
  },
  methods: {
    
    onFileChange (files) {
      this.file = files[0]
    },

    isImage (type) {
      return some(["image/png", "image/jpg"], t => t === type)         
    },

    isPdf (type) {
      return type === "application/pdf"
    },

    async onSend () {

      let formData = new FormData()
      
      formData.append("orderId", this.orderId)
      formData.append("content", this.content)
      formData.append("file", this.file)

      try {
        this.isPosting = true
        await http.post("ordercomment", 
          formData, {
          headers: {
              "Content-Type": "multipart/form-data"
          }
        })
        this.isPosting = false

        this.file = ""
        this.content = ""

        await this.getComments(true)

      } catch (error) {
        this.isPosting = false
        this.$store.dispatch("notify", { type: "error", text: error })
      }
    },

    async getOrder () {
      this.order = await http.get(`Order?id=${this.orderId}`)
    },
    

    async getComments (smoothScroll) { 
      
      this.isLoading = true

      try {
      
        this.comments = await http.get("OrderComments", {
          params: {
            orderId: this.orderId,
            withAttachment: true
          }
        })

        if (this.comments.length > 0) {        
          this.$nextTick(() => {
            const scrollIntoViewOptions = smoothScroll ? { behavior: "smooth", block: "center" } : {}
            const el = this.$refs[`comment-${this.comments.length - 1}`]
            if (el) {
              el[0].scrollIntoView(scrollIntoViewOptions)
            }
          })
        }
  
        this.isLoading = false

      } catch (error) {
        
        this.isLoading = false
        
        this.$store.dispatch("notify", { 
          type: "error", 
          text: error 
        })
        
      }
    },


  },

  async created () {

    await this.getOrder()
    
    await this.getComments(false)

    this.getCommentsTimer = setInterval(() => {
      this.getComments(true)
    }, 10000)

  },

  beforeDestroy () {    
    clearInterval(this.getCommentsTimer)
  }
  
}
</script>