<template>
  <details
    :id="'customs__' + transaction.id"
    :class="{ 'color-red-600': hasError }"
    :open="showCustoms"
  >
    <summary
      :aria-controls="'#customs__' + transaction.id"
      :aria-expanded="showCustoms"
      @click="showCustoms = !showCustoms"
    >
      <slot>
        <b>{{ transaction.name }}</b>
      </slot>
      <span class="ml-2 text-gray-400">({{ foreignPallets }} / plukket: {{ productQuantity }})</span>
      <div
        class="text-red-600"
        v-if="hasError"
      >
        <FontAwesomeIcon :icon="['far', 'exclamation-circle']" /> For mange paller til fortolling
      </div>
    </summary>
    <div
      v-if="isLoading"
      class="flex items-center mt-2"
    >
      <FontAwesomeIcon
        :icon="['far', 'spinner']"
        pulse
      />
      <div class="ml-2">
        <PTranslation k="Common.General.PleaseWait" />
      </div>
    </div>
    <table
      v-else
      class="mt-2"
    >
      <TransactionCustomsCountry
        v-for="country in countries"
        :key="'transaction__' + country.id"
        :country="country"
        :hasError="hasError"
        :max="productQuantity"
        :transactionCustom="transactionCustoms.find(t => t.countryId === country.id)"
        :transactionId="transaction.id"
        @updateCustom="updateTransactionCustoms"
        @removeCustom="removeTransactionCustoms"
      />
    </table>
  </details>

</template>

<script>
import http from "@/http"
import TransactionCustomsCountry from "./TransactionCustomsCountry.vue"

export default {
  props: ["transaction"],
  components: { TransactionCustomsCountry },
  data() {
    return {
      countries: [],
      isLoading: false,
      showCustoms: true,
      transactionCustoms: [],
    }
  },
  watch: {
    foreignPallets() {
      if (this.foreignPallets > 0) this.showCustoms = true
    }
  },
  computed: {
    productQuantity() {
      return this.transaction.quantityPicked - this.transaction.quantityExtra
    },
    foreignPallets() {
      let pallets = 0
      this.transactionCustoms.forEach(custom => {
        if (custom.quantity > 0) pallets += custom.quantity
      })
      return pallets
    },
    hasError() {
      return this.foreignPallets > this.productQuantity
    },
  },
  methods: {
    async getCustomsCountries() {
      this.isLoading = true
      this.countries = await http.get('TransactionCustomsCountries')
      // this.countries.map(c => c.loading = false)
      this.transactionCustoms = await http.get('TransactionCustoms?transactionId=' + this.transaction.id)
      this.isLoading = false
    },
    updateTransactionCustoms(transaction) {
      // console.log("This is new transation", transaction)
      if (this.transactionCustoms.findIndex(tc => tc.countryId === transaction.countryId) < 0) this.transactionCustoms.push(transaction)
      else
        this.transactionCustoms = this.transactionCustoms.map(ct => {
          if (ct.countryId === transaction.countryId) ct.quantity = transaction.quantity
          return ct
        })
    },
    removeTransactionCustoms(countryId) {
      this.transactionCustoms = this.transactionCustoms.map(tc => { if (tc.countryId === countryId) tc.quantity = 0; return tc; })
    }
  },
  mounted() {
    this.getCustomsCountries()
  }
}
</script>